.tiny-slider {
 position: relative;
 margin-top: 3%; 
}
.ts-control ul{
  list-style: none;
}
.ts-controls li{
  position: absolute;
  z-index: 5;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  background: rgba(0,0,0,0.5);
  padding: 12px 20px;
  border-radius: 50%;
  cursor: pointer;
}

.ts-controls li:hover{
  background: #3A69B0;
}

.ts-controls li.prev{
  left: -13px;
}

.ts-controls li.next{
  right: 10px;
}
.tns-item{
    text-align:center;
}