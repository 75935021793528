:root {
  --font-family-base: Open Sans, sans-serif;
  --font-family-heading: Schibsted Grotesk, sans-serif;
  --font-family-monospace: Fira Mono, monospace;
  --font-family-logo: Schibsted Grotesk, sans-serif;

  --color-primary-bg: #3A69b0;
  --color-primary-bg-2: #e6f2ff;
  --color-primary-bg-3: #e6f2ff;
  --color-primary-text: #f9fafb;
  --color-base-bg: #ffffff;
  --color-base-bg-2: #e6f2ff;
  --color-base-bg-3: #c7cacd;
  --color-base-text: #191a1a;
  --color-base-text-2: #555555;
  --color-header-text:  #111827;
  
  --logo-text-font-size: 28px;
  --logo-text-font-weight: 500;

  --menu-font-family: Open Sans, sans-serif;
  --menu-font-size: ;
  --menu-text-transform: "Capitalize";
  --menu-font-weight: 400;

  --header-height: 60px;
  --section-padding: 80px;
  --card-border-radius: 5px;
  --button-border-radius: 5px;
}

html[data-bs-theme='dark'] {
  --color-primary-bg: #3A69b0;
  --color-primary-bg-2: #e6f2ff;
  --color-primary-bg-3: #e6f2ff;
  --color-primary-text: #f9fafb;
  --color-base-bg: #121418;
  --color-base-bg-2: #3A69b0;
  --color-base-bg-3: #24272d;
  --color-base-text: #F4F4F5;
  --color-base-text-2: #D1D5DB;
  --color-logo-text: #F4F4F5;
  --color-header-text: #F4F4F5;
}

@import 'style';